
import store from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ActiveConversationMessages extends Vue {
  @Prop() interlocutorId!: string;

  get freeCharactersLeft() {
    return store.state.messaging.conversations[this.interlocutorId].freeCharactersLeft;
  }
}
