
import { pushError } from "@/core";
import { Component, Prop, Vue } from "vue-property-decorator";
import { create } from "./additionalPaymentRequestWebServices";

@Component({
    components: {
    }
})
export default class AskForPayment extends Vue {
    @Prop() toUserId!: string;

    model = { description: "", amount: 100 }
    isLoading = false;

    showDialog() {
        (this.$refs['modal'] as any).show();
        this.model = { description: "", amount: 100 };
    }
    hide() {
        (this.$refs['modal'] as any).hide();
    }
    submit() {
        if(!this.model.description || !this.model.amount) {
            pushError(this.$t("Please enter amount and description"));
            return;
        }
        this.isLoading = true;
        create({
            toUserId: this.toUserId,
            description: this.model.description,
            amount: this.model.amount
        })
        .then(() => {
            this.hide();
        })
        .finally(() => {
            this.isLoading = false;
        })
        .catch(error => {
            pushError(error.response?.data?.error || error);
        });
    }
}
