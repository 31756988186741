
import Vue from "vue";
import ConversationList from "@/messaging/ConversationList.vue";
import ConversationDetails from "@/messaging/ConversationDetails.vue";
import Component from "vue-class-component";
import store from "@/store";
import AssistantSlider from "@/home/AssistantSlider.vue";
import {AccountType} from "@/register/registerModel";

@Component({
  components: {
    ConversationList,
    ConversationDetails,
    AssistantSlider
  },
})
export default class Conversations extends Vue {
  conversationListIsShown = false;

  get isUserAssistant() {
    return store.getters.module.user?.accountType === AccountType.Assistant;
  }

  get isConversationActive() {
    return !!this.$route.params.id;
  }

  get assistantSearchRedirectUrl() {
    return store.getters.module.assistantSearchUrlToRedirect
  }

  onClickToggleConversations() {
    this.conversationListIsShown = !this.conversationListIsShown

  }
}
