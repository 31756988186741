
import { pushError } from "@/core";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountType } from "../register/registerModel";
import store from "../store";
import { AdditionalPaymentRequest } from "./additionalPaymentRequestWebServices";
import AdditionalPaymentRequestDetailsAndButtons from "./AdditionalPaymentRequestDetailsAndButtons.vue";

@Component({
  components: {
    AdditionalPaymentRequestDetailsAndButtons
  },
})
export default class AdditionalPaymentRequestListItem extends Vue {
  @Prop() request!: AdditionalPaymentRequest;

  get isUserClient() {
    return store.getters.module.user!.accountType === AccountType.Client;
  }
  get isAccepted() {
    return !!this.request.dateApproved;
  }

  get isRejected() {
    return !!this.request.dateRejected;
  }

  get isFinalAccepted() {
    return !!this.request.dateFinalApproved;
  }

  get variant() {
    if(this.isFinalAccepted) {
      return "light";
    }
    if(this.isRejected) {
      return "light";
    }
    return "";
  }
}
