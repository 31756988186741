
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import {
  finishConversation,
  Message,
  sendAttachments,
  sendGif,
  sendMessage,
  validateMessage,
  ValidateMessageResult,
} from "./messagingWebServices";
import { AccountType } from "@/register/registerModel";
import { userCanConverse, userHasCoins } from "./helpers";
import { handleNewSimpleMessage, handleSendMessageResult } from "./messagingHandlers";
import { Picker } from "emoji-mart-vue";
import { replaceEmojis } from "./emojisTable";
import { getBaseServiceUrl } from "@/helpers/settingsHelper";
import VueUploadComponent from "vue-upload-component";
import AskForPayment from "./AskForPayment.vue";
import { pushError } from "@/core";
import Giphy from "@/components/Giphy.vue";
import ActiveConversationMessages from "./ActiveConversationMessages.vue";

@Component({
  components: {
    Picker,
    VueUploadComponent,
    AskForPayment,
    Giphy,
    ActiveConversationMessages
  },
})
export default class SendMessage extends Vue {
  @Prop() interlocutorId!: string;

  form = {
    message: "",
  };
  isLoading = false;
  emojiPickerShown = false;
  gifPickerShown = false;
  attachments: VUFile[] = [];
  emojiViewScroll: number = 0;

  get isUserClient() {
    return store.getters.module.user!.accountType === AccountType.Client;
  }

  get interlocutor() {
    return store.getters.messaging.interlocutorInfo(this.interlocutorId)
  }

  get serviceUrl() {
    return getBaseServiceUrl();
  }

  toggleEmojiPicker(event: Event) {
    function findPos(obj: HTMLElement): number {
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent as HTMLElement));
      }
      return curtop;
    }
    if (this.userCanConverse) {
      if (!this.emojiPickerShown) {
        this.emojiViewScroll = findPos(event.target as HTMLElement) + 120;
        window.scrollTo(0, this.emojiViewScroll);
      }
      this.emojiPickerShown = !this.emojiPickerShown;
    }
  }

  toggleGifPicker() {
    this.gifPickerShown = !this.gifPickerShown;
  }

  hidePickers() {
    this.emojiPickerShown = false;
    this.gifPickerShown = false;
  }

  addEmoji(emoji: { native: string }) {
    this.form.message += emoji.native;
  }

  analyzeText() {
    this.form.message = replaceEmojis(this.form.message);
  }

  remove(attachmentId: string) {
    this.attachments = this.attachments.filter((a) => a.id !== attachmentId);
  }

  get headers() {
    return {
      authorization: `Bearer ${store.getters.module.user!.token}`,
    };
  }

  get userHasCoins() {
    return userHasCoins();
  }

  get sendMessageText() {
    if (userCanConverse(this.interlocutorId)) {
      return this.$t("Send a new message");
    }

    if (this.isUserClient) {
      if (!this.userHasCoins && !this.isConversationActive) {
        return this.$t("You have to top up the coins before conversation.");
      }
    } else {
      return this.$t("Client has not started chatting");
    }
  }

  newline() {
    this.form.message += "\n";
  }

  onFinishChatting() {
    finishConversation(this.interlocutorId).catch((error) => {
      pushError(error.response?.data?.error || error);
    });
  }

  async onSubmit() {
    await this.sendMessage();
  }

  async sendMessage() {
    this.hidePickers();
    this.isLoading = true;

    if (this.attachments.length > 0) {
      try {
        const attachmentsResponse = await sendAttachments(
          this.interlocutorId,
          this.attachments.map((a) => a.file))

        await handleNewSimpleMessage(attachmentsResponse.data)
        this.attachments = [];
      } catch (error) {
        pushError((error as any).response?.data?.error || error);
      } finally {
        this.isLoading = false
      }
    }

    if (this.form.message) {
      
      this.form.message = this.form.message.trim();

      const validationResult = await this.validateMessage()
      if (!validationResult) {
        return
      }

      const messageToSend = this.form.message
      this.form.message = "";

      const tempMessage: Message = {
        id: `temp-${Math.round(Math.random() * 100000000)}`,
        content: messageToSend,
        dateSent: new Date().toString(),
        fromUserId: store.getters.module.user!.id,
        toUserId: this.interlocutorId,
      } as Message
      await store.dispatch.messaging.addTempSentMessage({
        message: tempMessage
      })

      try {
        const sendMessageResult = await sendMessage(this.interlocutorId, messageToSend)
        await handleSendMessageResult(sendMessageResult.data)

      } catch (error) {
        const errorMessage = (error as any).response?.data?.error || error;
          if (errorMessage === "ClientDoesNotHaveCoins") {
            pushError(this.$t("Client does not have enough coins to cover your message."));
          } else if (errorMessage === "ConversationNotStartedOrClientOffline") {
            pushError(this.$t("Client is offline or conversation has not started."));
          } else {
            pushError(errorMessage);
          }
      } finally {
        this.isLoading = false;
      }
    }
  }

  async validateMessage(): Promise<boolean> {
    try {
      const validateResponse = await validateMessage(this.form.message)
      if (validateResponse.data == ValidateMessageResult.MessageContainsContactInformation) {
        const msgBoxConfirmResult = await this.$bvModal.msgBoxConfirm(
          this.$t("Providing email or phone number is only allowed if is needed as a part of service. Otherwise all the communication has to go through AssistantLine").toString(),
          {
            title: this.$t("Terms of Service reminder").toString(),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: this.$t("Send anyway").toString(),
            cancelTitle: this.$t("Cancel").toString(),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        })
        
        return !!msgBoxConfirmResult
        
      } else {
        return true
      }
    } catch (error) {
      return false
    } finally {
      this.isLoading = false
    }
  }

  mounted() {
    window.addEventListener("scroll", () => {
      if (this.emojiPickerShown) {
        window.scrollTo(0, this.emojiViewScroll);
      }
    })
  }

  sendGif(gifUrl: string) {
    this.hidePickers();
    this.isLoading = true;

    sendGif(this.interlocutorId, gifUrl)
      .then((response) => {
        handleNewSimpleMessage([response.data]).catch()
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }

  get userCanConverse() {
    return userCanConverse(this.interlocutorId);
  }

  get isConversationActive() {
    return store.getters.messaging.isConversationActive(this.interlocutorId)
  }
}
