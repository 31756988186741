
import { getBaseImagesUrl, getConversationAttachmentsUrl } from "@/helpers/settingsHelper";
import store from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AdditionalPaymentRequestStatus, Message } from "./messagingWebServices";
import AdditionalPaymentRequestDetailsAndButtons from "./AdditionalPaymentRequestDetailsAndButtons.vue";
import AssistantAvatar from "../assistants/AssistantAvatar.vue";

interface MessageListItem {
  message: Message,
  isMine: boolean
}

@Component({
  components: {
    AdditionalPaymentRequestDetailsAndButtons,
    AssistantAvatar
  }
})
export default class MessageList extends Vue {
  @Prop() conversationId!: string;

  updated() {
    this.scrollToLatestMessage()
  }

  get user() {
    return store.getters.module.user
  }
  get userName() {
    return `${this.user?.firstName} ${this.user?.lastName[0]}.`
  }

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  get assistant() {
    return store.getters.messaging.interlocutorInfo(this.conversationId);
  }

  get interlocutorName() {
    return `${this.assistant?.firstName} ${this.assistant?.lastName[0]}.`
  }

  isAttachment(message: MessageListItem) {
    return message.message.attachmentName;
  }

  isConversationEnding(message: MessageListItem) {
    return message.message.isConversationEnding;
  }

  attachmentLink(blobName: string) {
    return `${getConversationAttachmentsUrl()}${blobName}`;
  }

  getAdditionalPaymentRequestText(message: Message) {
    switch(message.additionalPaymentRequestStatus) {
      case AdditionalPaymentRequestStatus.New:
        return `${this.$t("New payment request")}: ${message.additionalPaymentRequest.comment}`;
      case AdditionalPaymentRequestStatus.Approved:
        return `${this.$t("Payment request accepted (deposit)")}: ${message.additionalPaymentRequest.comment}`;
      case AdditionalPaymentRequestStatus.FinalApproved:
        return `${this.$t("Payment request approved (paid)")}: ${message.additionalPaymentRequest.comment}`;
      case AdditionalPaymentRequestStatus.Rejected:
        return `${this.$t("Payment request rejected")}: ${message.additionalPaymentRequest.comment}`;

    }
  }

  get messageList() : MessageListItem[] {
    const messages: Message[] | undefined =
      store.state.messaging.conversations[this.conversationId]?.messages;
    if (!messages) {
      return [];
    }
    return messages
      .sort((a, b) => {
        return new Date(a.dateSent).getTime() - new Date(b.dateSent).getTime();
      })
      .map((m) => {
        return {
          message: m,
          isMine: m.fromUserId === store.getters.module.user!.id,
        };
      });
  }

  private scrollToLatestMessage(): void {
    if (!this.$el.getElementsByClassName) return; // can happen if not yet mounted
    const messagesWindow = this.$el as HTMLElement;

    if(!messagesWindow) {
      return
    }

    if (messagesWindow.scrollTop === 0 ||
      messagesWindow.scrollHeight -
        messagesWindow.scrollTop -
        messagesWindow.offsetHeight -
        messagesWindow.children[messagesWindow.childElementCount - 1]
        .scrollHeight < 15
    ) {
      messagesWindow.scrollTo(0, messagesWindow.scrollHeight);
    }
  }
}
