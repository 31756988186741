
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConversationListItem } from "./messagingWebServices";
import { getBaseImagesUrl } from "../helpers/settingsHelper";

@Component
export default class ConversationListItemView extends Vue {
  @Prop() conversation!: ConversationListItem;

  get hasUnreadMessage() {
    return this.conversation.unreadMessages > 0
  }

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  get formatDate() {
    return new Date(this.dateOfLastMessage).toDateString() ===
      new Date().toDateString()
      ? "hh:mm"
      : "DD.MM";
  }

  get dateOfLastMessage() {
    return new Date(this.conversation.lastMessageDateSent);
  }

  get lastMessageContent() {
    if(this.conversation.lastMessageIsConversationEnding) {
      return this.$t("Conversation ended");
    }

    if (this.conversation.lastMessageIsAttachment) {
      return `(${this.$t("attachment")})`;
    }

    if (this.conversation.lastMessageIsConversationEnding) {
      return 'gif';
    }

    return this.conversation.lastMessageContent;
  }

  get interlocutorName() {
    return `${this.conversation?.interlocutorFirstName} ${this.conversation?.interlocutorLastName[0]}.`
  }

  get interlocutorAvatarId() {
    return this.conversation.interlocutorAvatarId;
  }
}
