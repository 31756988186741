import { User } from '@/serviceClients/userWebServices';
import * as axios from 'axios';
import { AdditionalPaymentRequestStatus } from './messagingWebServices';

const url = `${process.env.VUE_APP_API_URL}/additionalpaymentrequest`;

export interface AdditionalPaymentRequest
{
    id: string;
    fromUserId: string;
    toUserId: string;
    comment: string;
    dateCreated: string;
    dateApproved?: string;
    dateRejected?: string;
    dateFinalApproved?: string;
    amount: number;
    status: AdditionalPaymentRequestStatus;
    toUser?: User;
    fromUser?: User;
}
export interface SaveAdditionalPaymentRequestRequest
{
    toUserId: string;
    description: string;
    amount: number;
}

export function getList(fromUserId: string, toUserId: string) {
    return axios.default.get<AdditionalPaymentRequest[]>(`${url}`, {
        params: {
            fromUserId,
            toUserId
        }
    });
}

export function create(model: SaveAdditionalPaymentRequestRequest) {
    return axios.default.post<string>(`${url}`, model);
}

export function acceptIntoEscrow(id: string) {
    return axios.default.put(`${url}/${id}/accept`);
}

export function reject(id: string) {
    return axios.default.put(`${url}/${id}/reject`);
}

export function finalApprove(id: string) {
    return axios.default.put(`${url}/${id}/final-approve`);
}