
import { pushError } from "@/core";
import { getLanguage } from "@/core/translate";
import { Component, Prop, Vue } from "vue-property-decorator";

type GiphyResult = {
  data: {
    type: "gif";
    embed_url: string;
    images: {
      preview_gif: {
        url: string;
      };
    };
  }[];
};

@Component
export default class Giphy extends Vue {
  @Prop() onImgClick?: (url: string) => any;

  query: string = "";
  queryResults: string[] = [];
  isSearching = false;
  timeout?: NodeJS.Timeout = undefined;

  apiKey = process.env.VUE_APP_GIPHY_API_KEY!;

  queryChange(value: string) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.searchGifs();
    }, 1000);
  }

  handleGif(url: string) {
    if (this.onImgClick) {
      this.onImgClick(url);
    }
  }

  private searchGifs() {
    const lang = getLanguage();
    const giphyUrl = `https://api.giphy.com/v1/gifs/search?api_key=${this.apiKey}&q=${this.query}&limit=25&offset=0&rating=g&lang=${lang}`;

    this.isSearching = true;
    fetch(giphyUrl)
      .then((response) => response.json())
      .then((result: GiphyResult) => {
        this.queryResults = result.data.map((x) => x.images.preview_gif.url);
      })
      .finally(() => {
        this.isSearching = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }
}
