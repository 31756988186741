import * as axios from 'axios';
import { User } from './messagingWebServices';

const url = `${process.env.VUE_APP_API_URL}/videoCall`;

export interface VideoCallModel{
    fromUserId: string;
    toUserId: string;
    isEndedAndBilled: boolean;
}
export interface IncomingCallModel
{
    fromUser: User;
    videoCallId: string;
}

export function start(interlocutorId: string) {
    return axios.default.post<string>(`${url}/${interlocutorId}`);
}

export function callDetails(videoCallId: string){
    return axios.default.get<VideoCallModel>(`${url}/${videoCallId}`);
}

export function ping(videoCallId: string) {
    return axios.default.put(`${url}/${videoCallId}/ping`);
}

export function end(videoCallId: string) {
    return axios.default.put(`${url}/${videoCallId}/end`);
}
