
import { Component, Vue } from "vue-property-decorator";
import { getBaseImagesUrl } from "../helpers/settingsHelper";
import ConversationListItemView from "./ConversationListItemView.vue";
import { pushError } from "@/core";
import store from "@/store";

@Component({
  components: {
    ConversationListItemView,
  },
})
export default class ConversationList extends Vue {
  isLoading = true;

  get activeConversationId() {
    return this.$route.params.id;
  }

  get conversationList() {
    return store.state.messaging.conversationListItems.sort(
      (a, b) =>
        new Date(b.lastMessageDateSent).getTime() -
        new Date(a.lastMessageDateSent).getTime()
    );
  }

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  async mounted() {
    this.isLoading = true;
    try {
      await store.dispatch.messaging.loadConversationListItems()
    } catch (error) {
      pushError(error.response?.data?.error || error, {
        title: this.$t("An error occurred while fetching data"),
      });
    } finally {
      this.isLoading = false;
      const isLowResultion = window.innerWidth < 768;

      if (!this.$route.params.id && this.conversationList.length && !isLowResultion) {
        await this.$router.push(`/conversations/${this.conversationList[0].interlocutorId}`)
      }
    }
  }
}
