
import { Component, Prop } from "vue-property-decorator";
import AssistantDetails from "@/assistants/Details.vue";
import { DetailsBase } from "@/assistants/DetailsBase";
import AvailabilityIndicator from "@/components/AvailabilityIndicator.vue";
import { OnlineStatus } from "@/serviceClients/userStatusWebServices";
import { getBaseImagesUrl } from "@/helpers/settingsHelper";
import store from "@/store";

@Component({
  components: {
    AssistantDetails,
    AvailabilityIndicator,
  },
})
export default class InterlocutorTopDetails extends DetailsBase {
  @Prop() interlocutorId!: string;
  @Prop() status!: OnlineStatus;

  get assistant() {
    return store.getters.messaging.interlocutorInfo(this.interlocutorId);
  }

  get interlocutorName() {
    return `${this.assistant?.firstName} ${this.assistant?.lastName[0]}.`
  }

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  get avatarId() {
    return this.assistant?.photos?.find((p) => true)?.id;
  }
}
