
import {
  AssistantInfo,
} from "@/assistants/assistantsWebServices";
import { AccountType } from "@/register/registerModel";
import store from "@/store";
import { Component, Prop } from "vue-property-decorator";
import { DetailsBase } from "../assistants/DetailsBase";
import AssistantAvatar from "../assistants/AssistantAvatar.vue";
import { displayHeight } from "@/accountSetup/assistantHeightHelper";
import { OnlineStatus } from "@/serviceClients/userStatusWebServices";
import { getLanguageName } from "@/core/translate";
import {EducationLevel, LanguageLevel} from "@/accountSetup/accountSetupService";
import {getLevelOptionTranslation} from "@/accountSetup/languageLevelHelper";
import RatingStars from "@/assistants/RatingStars.vue";

@Component({
  components: {
    AssistantAvatar,
    RatingStars
  }
})
export default class InterlocutorSideDetails extends DetailsBase {
  @Prop() interlocutor!: AssistantInfo;
  @Prop() interlocutorId!: string;
  @Prop() isInterlocutorActive!: boolean;
  @Prop() status!: OnlineStatus;

  get assistant() {
    return store.getters.messaging.interlocutorInfo(this.interlocutorId);
  }

  get educationLevel() {
    switch (this.assistant?.educationLevel) {
      case EducationLevel.Higher:
        return this.$t("Higher");
      case EducationLevel.Student:
        return this.$t("Student");
      case EducationLevel.Student:
        return this.$t("Other");
      default:
        return undefined;
    }
  }

  languageName(code: string) {
    return getLanguageName(code);
  }

  languageLevel(level: LanguageLevel) {
    return getLevelOptionTranslation(this, level);
  }

  get interlocutorName() {
    return `${this.assistant?.firstName} ${this.assistant?.lastName[0]}.`
  }

	get available() {
		return this.status === OnlineStatus.Available;
	}

  get isUserClient() {
    return store.getters.module.user?.accountType === AccountType.Client;
  }

  get cityExists() {
    return !!this.assistant?.address?.city
  }
  get countryExist() {
    return !!this.assistant?.address?.country
  }
  get country() {
    return this.assistant?.address?.country === 'PL'? this.$t('Poland'): ''
  }

  get height() {
    return displayHeight(this.assistant?.height)
  }
}
