
import { Component, Prop, Vue } from "vue-property-decorator";
import { start } from "./videoCallWebServices";
import { pushError } from "@/core";

@Component
export default class StartVideoCall extends Vue {
  @Prop() interlocutorId!: string;
  @Prop() isOtherUserAvailable!: boolean;
  isLoading = false;

  onClick() {
    this.isLoading = true;

    start(this.interlocutorId)
      .then((response) => {
        this.$router.push(`/conversations/call/${response.data}`).catch(() => { /* noop */ });
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }
}
