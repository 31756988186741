
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getBaseImagesUrl } from "@/helpers/settingsHelper";
import store from "@/store";
import SendMessage from "./SendMessage.vue";
import InterlocutorTopDetails from "./InterlocutorTopDetails.vue";
import callOnInterval from "../helpers/callOnInterval";
import { onlineStatus, OnlineStatus } from "@/serviceClients/userStatusWebServices";
import StartVideoCall from "./StartVideoCall.vue";
import { AccountType } from "@/register/registerModel";
import AssistantDetails from "@/assistants/Details.vue";
import MessageList from "@/messaging/MessageList.vue";
import InterlocutorSideDetails from "./InterlocutorSideDetails.vue";
import AdditionalPaymentRequestList from "./AdditionalPaymentRequestList.vue";
import { pushError } from "@/core";
import { userHasCoins } from "./helpers";
import AssistantAvatar from "@/assistants/AssistantAvatar.vue";

@Component({
  components: {
    SendMessage,
    StartVideoCall,
    InterlocutorTopDetails,
    AssistantDetails,
    InterlocutorSideDetails,
    MessageList,
    AdditionalPaymentRequestList,
    AssistantAvatar
  },
})
export default class ConversationDetails extends Vue {
  @Prop() interlocutorId!: string;

  // property isn't reactive if undefined :( hence null
  clearStatusInterval: () => void = () => {};
  clearActivityInterval: () => void = () => {};
  otherUserStatus: OnlineStatus = OnlineStatus.Offline;

  personDataShown = false
  isLoading = true;
  message = "";

  get interlocutor() {
    return store.getters.messaging.interlocutorInfo(this.interlocutorId)
  }

  get interlocutorName() {
    return `${this.interlocutor?.firstName} ${this.interlocutor?.lastName[0]}.`
  }

  onClickToggleUserData() {
    this.personDataShown = !this.personDataShown
  }

  get isLoggedInAsAssistant() {
    return store.getters.module.user?.accountType === AccountType.Assistant;
  }

  get isUserClient() {
    return store.getters.module.user!.accountType === AccountType.Client;
  }

  get userHasCoins() {
    return userHasCoins();
  }

  get chargedCoins() {
    return store.state.messaging.conversations[this.interlocutorId]?.chargedCoins;
  }

  get imagesUrl() {
    return getBaseImagesUrl()
  }

  async mounted() {
    await this.onIdChanged()
  }

  get isOtherUserAvailable() {
    return this.otherUserStatus === OnlineStatus.Available;
  }

  beforeDestroy() {
    this.clearStatusInterval();
    this.clearActivityInterval();
  }

  @Watch("interlocutorId")
  async onIdChanged() {
    store.commit.module.setActiveModuleName(this.$t("Messages"));
    this.clearStatusInterval();

    this.scrollMessageListToEnd();
    this.isLoading = true;

    await store.dispatch.messaging.loadConversation({ interlocutorId: this.interlocutorId })
    store.commit.module.setActiveModuleName(`${this.interlocutor?.firstName} ${this.interlocutor?.lastName} - ${this.$t('Messages')}`);

    this.scrollMessageListToEnd();
    this.isLoading = false;

    try {
      this.startMonitoringUserStatus();
    } catch (error) {
      pushError(error.response?.data?.error || error, {
        title: this.$t("An error occurred while fetching data"),
      });
    }
  }

  startMonitoringUserStatus() {
    this.clearStatusInterval = callOnInterval(
      () => {
        if (!this.interlocutorId || !this.interlocutor) {
          return Promise.resolve({
            data: OnlineStatus.Offline,
            status: 200,
            statusText: "",
            config: {},
            headers: undefined,
          });
        }
        return onlineStatus(this.interlocutorId);
      },
      2000,
      (status) => {
        this.otherUserStatus = status;
      }
    );
  }

  async startVideoCall() {
    await this.$router.push(`/call/${this.interlocutorId}`);
  }

  private scrollMessageListToEnd() {
    const messagesWindow = this.$el.getElementsByClassName(
      "message-list"
    )[0] as HTMLElement;
    messagesWindow.scrollTo(0, messagesWindow.scrollHeight);
  }
}
