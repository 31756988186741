
import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountType } from "../register/registerModel";
import store from "../store";
import { AdditionalPaymentRequest, getList, reject } from "./additionalPaymentRequestWebServices";
import AdditionalPaymentRequestListItem from "./AdditionalPaymentRequestListItem.vue";
import { pushError } from "@/core";

@Component({
  components: {
    AdditionalPaymentRequestListItem
  },
})
export default class AdditionalPaymentRequestList extends Vue {
  @Prop() interlocutorId!: string;

  model = { description: "", amount: 100 };
  isLoading = false;
  requests: AdditionalPaymentRequest[] = [];

  get isUserClient() {
    return store.getters.module.user!.accountType === AccountType.Client;
  }

  loadData() {
    const assistantId = store.getters.module.user!.accountType == AccountType.Assistant
    ? store.getters.module.user!.id : this.interlocutorId;
    const clientId = assistantId == this.interlocutorId ? store.getters.module.user!.id : this.interlocutorId;
    this.isLoading = true;
    getList(assistantId, clientId)
        .then((response) => {
            this.requests = response.data;
        })
        .finally(() => {
            this.isLoading = false;
        })
        .catch(error => {
          pushError(error.response?.data?.error || error);
        });
  }

  mounted() {
    this.loadData();

    this.$watch(() => this.interlocutorId, () => {
      this.loadData();
    });

    this.$root.$on("PaymentRequestSaved", (request: AdditionalPaymentRequest) => {
      const alreadyExisting = this.requests.find(r => r.id == request.id);
      if(!alreadyExisting) {
        this.requests.unshift(request);
      } else {
        alreadyExisting.dateRejected = request.dateRejected;
        alreadyExisting.dateApproved = request.dateApproved;
        alreadyExisting.dateFinalApproved = request.dateFinalApproved;
      }
      store.dispatch.module.loadBalance().catch(() => { /* noop */ });
    });
  }
}
