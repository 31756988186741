
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import { ping, end } from "../messaging/videoCallWebServices";
import { getJitsiDomain } from "../helpers/settingsHelper";

declare const JitsiMeetExternalAPI: any;

@Component
export default class Jitsi extends Vue {
  @Prop() private interlocutorId!: string;
  @Prop() private videoCallId!: string;

  private api: any | undefined = undefined;
  private pingIntervalHandle: NodeJS.Timeout | undefined = undefined;
  private readonly pingIntervalInSeconds = 5;

  async mounted() {
    await (Vue as any).loadScript('https://meet.jit.si/external_api.js');

    const maxHeight = 700;
    const domain = getJitsiDomain();
    const options = {
      roomName: this.videoCallId,
      configOverwrite: {
        disableDeepLinking: true,
      },
      interfaceConfigOverwrite: {
        MOBILE_APP_PROMO: false,
        HIDE_INVITE_MORE_HEADER: true,
        RECENT_LIST_ENABLED: false,
        SHOW_JITSI_WATERMARK: false,
        DISABLE_PRESENCE_STATUS: true,
        DISPLAY_WELCOME_PAGE_CONTENT: false,
        ENABLE_DIAL_OUT: false,
        filmStripOnly: false,
        GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
        INITIAL_TOOLBAR_TIMEOUT: 1,
        SHOW_POWERED_BY: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
      },
      width: this.$el.clientWidth,
      height:
        this.$el.clientWidth > maxHeight ? maxHeight : this.$el.clientWidth,
      parentNode: document.querySelector("#meet"),
      userInfo: {
        displayName: store.getters.module.user!.firstName,
      },
    };
    this.api = new JitsiMeetExternalAPI(domain, options);
    this.api.addEventListener("videoConferenceLeft", () => {
      this.$toasted.info("Call ended", { duration: 2000 });
      this.$router.push(`/conversations/${this.interlocutorId}`).catch(() => { /* noop */ });
    });
    this.api.addEventListener("participantLeft", () => {
      this.$toasted.info("Call ended by the other person", { duration: 2000 });
      this.$router.push(`/conversations/${this.interlocutorId}`).catch(() => { /* noop */ });
    });

    this.startPinging();
  }

  beforeDestroy() {
    if (this.pingIntervalHandle) {
      clearInterval(this.pingIntervalHandle);
    }
    this.api.dispose();
    end(this.videoCallId).then(() => {
      store.dispatch.module.loadBalance().catch(() => { /* noop */ });
    }).catch(() => { /* better stay silent */ });
  }

  startPinging() {
    this.pingIntervalHandle = setInterval(
      () => this.performPing(),
      this.pingIntervalInSeconds * 1000
    );
  }

  performPing() {
    const numberOfParticipants = this.api.getNumberOfParticipants();
    if (numberOfParticipants > 1) {
      ping(this.videoCallId).catch(() => { /* no need to bother user here */ });
    }
  }
}
