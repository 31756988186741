
import { pushError } from "@/core";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountType } from "../register/registerModel";
import store from "../store";
import { AdditionalPaymentRequest, getList, reject, acceptIntoEscrow, finalApprove } from "./additionalPaymentRequestWebServices";

@Component({
  components: {},
})
export default class AdditionalPaymentRequestDetailsAndButtons extends Vue {
  @Prop() request!: AdditionalPaymentRequest;

  get isUserClient() {
    return store.getters.module.user!.accountType === AccountType.Client;
  }
  isLoading = false;

  get isAccepted() {
    return !!this.request.dateApproved;
  }

  get isRejected() {
    return !!this.request.dateRejected;
  }

  get isFinalAccepted() {
    return !!this.request.dateFinalApproved;
  }

  get variant() {
    if(this.isFinalAccepted) {
      return "light";
    }
    if(this.isRejected) {
      return "light";
    }
    return "";
  }

  mounted() {
    this.$root.$on("PaymentRequestSaved", (request: AdditionalPaymentRequest) => {
      if(this.request.id == request.id) {
        this.request.dateRejected = request.dateRejected;
        this.request.dateApproved = request.dateApproved;
        this.request.dateFinalApproved = request.dateFinalApproved;
      }
    });
  }

  accept() {
    this.isLoading = true;
    acceptIntoEscrow(this.request.id)
      .then(() => {
        this.request.dateApproved = new Date().toString();
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        if(error?.response?.data?.error === "InsufficientFunds") {
          this.$toasted.error(
            this.$t("You don't have sufficient funds to pay for this request. Please top up your account.").toString(),
            {
              position: 'bottom-center',
              action: {
                    text: this.$t('Top up coins').toString(),
                    onClick: (e, toastObject) => {
                        this.$router.push(`/transactions`).catch(() => { /* noop */ });
                        toastObject.goAway();
                    }
                }
            });
        } else {
          pushError(this.$t("An error occured during the payment. Please try again.").toString());
        }
      });
  }

  reject() {
    this.isLoading = true;
    reject(this.request.id)
      .then(() => {
        this.request.dateRejected = new Date().toString();
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch(error => {
        pushError(error);
      });
  }

  finalizePayment() {
    this.isLoading = true;
    finalApprove(this.request.id)
      .then(() => {
        this.request.dateFinalApproved = new Date().toString();
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch(error => {
        alert(error);
      });
  }
}
