
import ConversationList from "@/messaging/ConversationList.vue";
import ConversationDetails from "@/messaging/ConversationDetails.vue";
import Jitsi from "@/components/Jitsi.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { callDetails } from "@/messaging/videoCallWebServices";
import store from "@/store";
import {
  AssistantInfo,
  getAssistantInformation,
} from "@/assistants/assistantsWebServices";
import { pushError } from "../core";

@Component({
  components: {
    ConversationList,
    ConversationDetails,
    Jitsi,
  },
})
export default class Call extends Vue {
  private interlocutorId: string | null = null;
  interlocutor: AssistantInfo | null = null;

  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Video call"));

    callDetails(this.$route.params.videoCallId)
    
    .then((response) => {
      const userId = store.getters.module.user!.id;
      this.interlocutorId =
        response.data.fromUserId === userId
          ? response.data.toUserId
          : response.data.fromUserId;

      return this.loadAssistantInfo(this.interlocutorId);
    })
    .catch((error) => {
      pushError(error.response?.data?.error || error);
    });
  }

  private loadAssistantInfo(interlocutorId: string) {
    return getAssistantInformation(interlocutorId).then((response) => {
      this.interlocutor = response.data;
      store.commit.module.setActiveModuleName(
        `${this.interlocutor?.firstName} ${
          this.interlocutor?.lastName
        } - ${this.$t("Video call")}`
      );
    });
  }
}
