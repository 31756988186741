const emojisTable: { [id: string]: string } = {
    ":D": "😁",
    ":P": "😜",
    ":)": "😀",
    ":(": "☹️",
    ":/": "😏",
    ":*": "😗",
    ";*": "😘",
    "<3": "❤️",
}

export const replaceEmojis = (text: string) => {
    const keys = Object.keys(emojisTable);
    keys.forEach(k => {
        text = text.replace(k, emojisTable[k])
    });
    return text;
}
